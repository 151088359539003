@import "sz";

html, body, #root {
  height: 95%;
}
.sz-table .sz-table-header {
  background-color:#A3D049 !important;
}
tbody {
  td {
    background: white;
    font-family: "DINPro Medium" 
  }
  p {
    margin-bottom: 0 !important;
  }
}
button:hover{
  background: var(--primary);
  color: var(--secondary);
}
button{
  background: var(--secondary);
  color: var(--primary);
  border: none;
}
.title{
	color: #000D42;
	font-size: 24px;
	font-weight: 900;
	letter-spacing: 1px;
	line-height: 28px;
}
.sub-title {
	color: #000D42 !important;
	font-size: 16px;
	font-weight: normal !important;
	letter-spacing: 0.5px !important;
  line-height: 26px !important;
  font-family: "DINPro-Medium";
}