
@import '../../../mixins';

.collapse-container button {
    border-bottom: 2px solid white !important;
    border: transparent ;
    text-align: left;
    font-family: DINPro-bold;
    height: 40px;
    border-radius:none !important;
    justify-content: flex-start !important;
    .danger{
        color:var(--danger-text)
    }
}
.collapse-container button:hover {
    border: transparent ;
}
.collapse-container button.transparent {
    border-bottom: none !important;
    width: 100%;
    background: transparent !important;  
}
.collapse-container button.transparent:disabled {
    color: grey;
}
.collapse-container button.no-hover::after{
    @include background(none); 
}
