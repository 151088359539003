.gaia-search {
    width: 558px;
    height: 26px;
}

.gaia-search__input {
    height: 40px;
}

.sz-form-group {
    @at-root #{&}__input-group-icon {
        z-index: 5;
        left: 513px;
        top: 0;
        bottom: 2px;
        width: 45px;
        font-size: 1.12rem;
        color: white !important;
        border: white 0.5px solid;
        background-color: #030e40;
    }

    @at-root #{&}__sz-input-text:focus {
        border: 2px solid #239de1;
    }

}

.gaia-suggestion-result {
    position: absolute;
    top: 48px;
    z-index: 10000;
    color: #072B4E;
    background: white 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: 'DINPro';
    width: 100%;
    padding-left: 0;
    font-weight: bold;
   
    li {
        list-style-type: none;
        font-size: 14px;
        line-height: 2rem;
        letter-spacing: 0;
        font-weight: lighter;
    }
    li:last-child {
        border-block-end: none;
     }
     li:hover{
         background-color: #EAEDF4;
         border-radius: 4px;
     }
}

#button-addon4 {
    height: 42px;
    margin-bottom: 6px;
    button:last-child {
        padding-left: 6px;

        padding-right: 6px;

        padding-top: 0px;

        padding-bottom: 0px;

        margin-left: 6px;

        margin-right: 6px;

        margin-bottom: 6px;

        margin-top: 6px;

        border-radius: 20%;
    }
} 


.btn-outline-secondary {
    color: #030e40 !important;
   
}
.input-group{
    background-color: white;
}
.input-group-append{
    background-color: white;
}

.search-input {
    border: none !important;
    box-sizing: content-box !important;
    border-bottom: 1px solid #A0CF44 !important;
    padding-bottom: 0px !important;
    margin-bottom: 6px !important;
    padding-left: 0px !important;
    button {
        .sz-icon-line {
            font-size: 15px;
            //line-height: 
        }
    }
}
.form-control {
    border-left:none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid var(--input-border-bottom-color) !important;
    box-shadow:none !important;
}
.sz-icon-line.search{
    font-size: 15px;
}
.sz-icon-line.remove{
    font-size: 15px;
}