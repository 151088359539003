@import "mixins";
.collapse-container{
  button{
    &.sz-btn{
      @include background(var(--collapse-bg-color));
      @include color(var(--collpase-text-color));
    }
}
}
button{
  &.sz-btn{
    @include background(var(--button-bg-color));
    @include color(var(--button-text-color));
    &.transparent{
      @include color(var(--outline-button-text-color));
      box-shadow: none;
    }
  }
  &.sz-btn:active{
    @include background(var(--button-bg-color));
  }
  &.sz-btn-outline{
    @include border-color(var(--outline-button-border-color));
    @include color(var(--outline-button-text-color));
  }
  &.sz-btn-outline:hover{
    @include background(var(--outline-button-hover-bg-color));
    @include color(var(--outline-button-hover-text-color));
  }
  &.sz-btn-outline:focus{
    box-shadow: none;
  }
}
.spinner-border{
  &.text-primary{
    @include color(var(--round-spinner-border-color));
  }
}
